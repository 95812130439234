import  { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseDirective,
  EbPaymentReport,
  EbPaymentReportFilter,
  EbPaymentReportList,
  McForm,
  McGod,
  WebFile,
  EbPaymentReportService,
  SortCriteria
} from '@miticon-ui/mc-core';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogComponent, MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import {FormControl, FormGroup} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { lowerCase } from 'lodash';
import { AppSharedMatBottomSheetComponent } from "../../../app-shared/components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: 'mc-bank-module-incoming-payments-list-page',
  templateUrl: './mc-bank-module-incoming-payments-list-page.component.html',
  styleUrls: ['./mc-bank-module-incoming-payments-list-page.component.scss'],
})
export class McBankModuleIncomingPaymentsListPageComponent extends BaseDirective implements OnInit {

  mcForm = new McForm();
  acceptDialogButtonsKvm = new Map<string, string>();
  ebPaymentReportList!: EbPaymentReportList;
  ebPaymentReportFilter = new EbPaymentReportFilter();
  ebPaymentReport = new EbPaymentReport();
  isLoading = false;
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  searchTooltip = McGod.t('cc.my-factoring.search-by-consumer-name-description-contract-number');
  pageNumber: number;
  pageSize: number;
  filtersForm = new FormGroup({
    multiselectStatus: new FormControl<any>(''),
    multiselectType: new FormControl<any>(''),
    fromDateDateRange: new FormControl<any>(''),
    toDateDateRange: new FormControl<any>('')
  });
  mkMatMenuActionItems: MkMatMenuItem[] = [
    {
      title: McGod.t('cc.incoming-payments.find-match'),
      actionCd: EbPaymentReport.ACTION_FIND_MATCH,
      permission: McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW,
      matIcon: 'my_location',
    },
    {
      title: McGod.t('cc.incoming-records.refund'),
      actionCd: EbPaymentReport.ACTION_REFUND,
      permission: McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW,
      matIcon: 'autorenew',
    },
    {
      title: `${McGod.t('cc.incoming-payments.mark-as-invalid')} / ${McGod.t('cc.incoming-payments.undo-mark-as-invalid')}`,
      actionCd: EbPaymentReport.ACTION_MARK_AS_INVALID,
      permission: McGod.PERM_MC_BILLING_MY_STATEMENT_VIEW,
      matIcon: 'close',
    },
  ];
  searchForm = new FormGroup({
    search: new FormControl<any>(''),
  });
  filters = new MkFilterOutput(new PageEvent(), new SortCriteria('id', 'DESC'));
  webFile = new WebFile();
  checkedPaymentReportList = new EbPaymentReportList();
  isOnlyUnmatched: boolean = false;
  isOnlyPaymentType: boolean = false;

  constructor(private ebPaymentReportService: EbPaymentReportService,
              private router: Router, private toastr: ToastrService,
              private dialog: MatDialog,
              private tS: TranslateService,
              private matBottomSheet: MatBottomSheet) {
    super();
  }

  ngOnInit() {
    this.ebPaymentReportList = new EbPaymentReportList();
    this.ebPaymentReportList.setPager(0, 100);
    this.ebPaymentReportService.paginationChanged$.pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.pageNumber = res.pageNumber;
        this.pageSize = res.pageSize;
      });

    // this.ebPaymentReportService.filterChanged$.pipe(takeUntil(this.destroyed$))
    //   .subscribe((res) => {
    //     this.ebPaymentReportFilter = res;
    //     setTimeout(() => {
    //       this.searchForm.get('search')?.setValue(this.ebPaymentReportFilter.searchTerm);
    //       this.filtersForm.get('multiselectStatus')?.setValue(this.ebPaymentReportFilter.statusCds);
    //       this.filtersForm.get('multiselectType')?.setValue(this.ebPaymentReportFilter.types);
    //       this.filtersForm.get('fromDateDateRange')?.setValue(this.ebPaymentReportFilter.transactionDatetimeFrom);
    //       this.filtersForm.get('toDateDateRange')?.setValue(this.ebPaymentReportFilter.transactionDatetimeTo);
    //     }, 1500);
    //   });
    this.initFilterConfig();
    this.actLoad(this.pageNumber, this.pageSize, this.ebPaymentReportFilter);
    this.initTableConfig();

    this.acceptDialogButtonsKvm.set(
      'btn-primary',
      McGod.t('cc.common.edit.yes')
    ); // Component works this way, value is also button css class
    this.acceptDialogButtonsKvm.set(
      'btn-secondary',
      McGod.t('cc.common.edit.no')
    );
  }

  actLoad(pageNumber: number, pageSize: number, filters: EbPaymentReportFilter) {
    this.isLoading = true;
    this.ebPaymentReportList.pageNum = pageNumber;
    this.ebPaymentReportList.pageItemsPerPageCount = pageSize;
    this.ebPaymentReportList.loadByFilter(filters, () => {
      this.isLoading = false;
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('cc.common.debtor-name'), 'getDebtorName()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.iban'), 'getIban()', 250, '');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.view.description'), 'getDescription()', '');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.date-and-time'), 'getTransactionDateTime()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.factoring.transactions.amount'), 'getAmount()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.jobs.edit.type'), 'getPaymentType()', 250, '');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.matching-type'), 'getMatchingType()', 200, '');
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.view.status'), 'getIconLabel()', 'status');
  }

  initFilterConfig() {
    const statusFilter = EbPaymentReport.getStatusVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const typeFilter = EbPaymentReport.getTypeVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const matchingTypeFilter = EbPaymentReport.getMatchingTypeVll().items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(EbPaymentReport.FILTER_STATUS), statusFilter);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(EbPaymentReport.FILTER_TYPE), typeFilter);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(EbPaymentReport.FILTER_MATCHING_TYPE), matchingTypeFilter);
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(EbPaymentReport.FILTER_DATE_RANGE));
  }

  onFilterChanged(filters: MkFilterOutput) {
    const filteredStatuses = filters.selections[McGod.t(EbPaymentReport.FILTER_STATUS)];
    this.isOnlyUnmatched =
      filteredStatuses &&
      filteredStatuses.length === 1 &&
      filteredStatuses[0].value === EbPaymentReport.STATUS_UNMATCHED;

    const filteredTypes = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(EbPaymentReport.FILTER_TYPE)]);
    this.isOnlyPaymentType =
      filteredTypes &&
      filteredTypes.length === 1 &&
      filteredTypes[0] === EbPaymentReport.TYPE_PAYMENT;

    const paymentReportFilter = new EbPaymentReportFilter();
    this.ebPaymentReportList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    paymentReportFilter.searchTerm = filters.search;
    paymentReportFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(EbPaymentReport.FILTER_STATUS)]);
    paymentReportFilter.types = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(EbPaymentReport.FILTER_TYPE)]);
    paymentReportFilter.matchingTypes = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(EbPaymentReport.FILTER_MATCHING_TYPE)]);
    paymentReportFilter.transactionDatetimeFrom = filters.selections[McGod.t(EbPaymentReport.FILTER_DATE_RANGE)]?.startDate;
    paymentReportFilter.transactionDatetimeTo = filters.selections[McGod.t(EbPaymentReport.FILTER_DATE_RANGE)]?.endDate;
    this.ebPaymentReportService.paginationChanged$.next({pageNumber: filters.pageEvent.pageIndex, pageSize: filters.pageEvent.pageSize});
    this.ebPaymentReportService.filterChanged$.next(paymentReportFilter);
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, paymentReportFilter);
  }

  onMkMatMenuItemsAction(event: MkMatTableMenuAction) {
    switch (event.action.actionCd) {
      case EbPaymentReport.ACTION_FIND_MATCH:
        if (event.item.status !== EbPaymentReport.STATUS_MATCHED && event.item.status !== EbPaymentReport.STATUS_INVALID) {
          this.router.navigate([`/entity/${McGod.getLoggedEntityIdFromToken()}/bank-module/incoming-payments-potential-transaction-matches/${event.item.id}`]);
        } else {
          this.toastr.error(McGod.t('cc.incoming-payments.you-can-only-match-incoming-records-in-status-unmatched'));
        }
        break;
      case EbPaymentReport.ACTION_MARK_AS_INVALID:
        if (event.item.status !== EbPaymentReport.STATUS_MATCHED && event.item.status !== EbPaymentReport.STATUS_EXPORTED) {
          this.ebPaymentReport.apiService.markAsInvalid(event.item.id).pipe(takeUntil(this.destroyed$)).subscribe((res) => {
              if (res.status !== EbPaymentReport.STATUS_UNMATCHED) {
                this.toastr.success(McGod.t('cc.incoming-payments.incoming-record-has-been-marked-as-invalid'));
              } else {
                this.toastr.success(McGod.t('cc.incoming-payments.incoming-record-status-has-been-returned-to-unmatched'));
              }
              this.actLoad(this.pageNumber, this.pageSize, this.ebPaymentReportFilter);
            },
            (err) => {
              this.toastr.error(err.error.message);
            }
          );
        } else {
          this.toastr.error(McGod.t('cc.incoming-payments.you-can-only-mark-as-invalid-incoming-record-which-are-not-in-statuses-matched-and-exported'));
        }
        break;
      case EbPaymentReport.ACTION_REFUND:
        const singleBody = {
          payoutTypeCd: 'EBICS',
          ebPaymentReportId: event.item.id
        };
        this.onRefundAction(singleBody, false);
        break;
    }
  }

  onRefundAction(body: any, isBulk: boolean): void {
    const dialogData = {
      height: '230px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.incoming-records.refund',
        description: this.tS.instant('cc.incoming-records.refund.confirmation'),
        cancelBtnLabel: 'cc.common.edit.no',
        confirmBtnLabel: 'cc.common.edit.yes'
      }
    };

    this.dialog.open(ConfirmationDialogComponent, dialogData).afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.processRefund(body, isBulk);
      }
    });
  }

  private processRefund(body: any, isBulk: boolean): void {
    const refundMethod = isBulk
      ? this.ebPaymentReportService.refundPaymentRecordBulk(body)
      : this.ebPaymentReportService.refundPaymentRecordSingle(body);

    refundMethod.subscribe(
      (data: any) => {
        if(isBulk) {
          let successRefunds: number = 0;
          data.forEach((item: any) => {
            successRefunds += item.numberOfStatements;
          });
          this.toastr.success(this.tS.instant("cc.refunded-payments") + ": " + successRefunds + "/" + body.ebPaymentReportIds.length);
        } else {
          this.toastr.success(this.tS.instant('cc.incoming-records.refund.success'));
        }

        this.actLoad(this.pageNumber, this.pageSize, this.ebPaymentReportFilter);
      },
      (error) => {
        const errorMessage = error.error?.message || this.tS.instant('cc.incoming-records.refund.unsuccess');
        this.toastr.error(errorMessage);
      }
    );
  }

  onItemsSelected(items: EbPaymentReport[]) {
    if (items.length === 0) {
      this.matBottomSheet.dismiss();
    }
    this.checkedPaymentReportList = new EbPaymentReportList();
    this.checkedPaymentReportList.items = items;
    const actions = [
      {icon: 'autorenew', title: McGod.t('cc.incoming-records.refund'), action: EbPaymentReport.ACTION_REFUND }
    ];
    const bulkContainerLength = document.getElementsByClassName('bulk-container').length;
    if (items.length > 0 && !bulkContainerLength && this.isOnlyUnmatched && this.isOnlyPaymentType) {
      const bulkDialog = this.matBottomSheet.open(AppSharedMatBottomSheetComponent, {
        closeOnNavigation: true,
        hasBackdrop: false,
        data: actions
      });

      bulkDialog.afterDismissed().subscribe((bulkAction: any) => {
        switch (bulkAction) {
          case EbPaymentReport.ACTION_REFUND:
            const bulkBody = {
              payoutTypeCd: 'EBICS',
              ebPaymentReportIds: items.map(item => item.id)
            };
            this.onRefundAction(bulkBody, true);
            break;
        }
      });
    }
  }

  openExportAllDialog() {
    const itemCount = this.ebPaymentReportList.totalItemsCount;
    this.dialog.open(ConfirmationDialogComponent,{
      height: '230px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.incoming-records.export-all-incoming-records',
        description: `${this.tS.instant('cc.incoming-records.are-you-sure-you-want-to-export')} ${itemCount} ${lowerCase(this.tS.instant('cc.incoming-records'))} ?`,
        cancelBtnLabel: 'cc.common.edit.cancel',
        confirmBtnLabel: 'cc.common.export'
      }
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
       this.handleExportAllIncomingPayments();
      }
    })
  }

  handleExportAllIncomingPayments() {
      const format = 'CSV';
      this.ebPaymentReportService
        .exportAll(this.ebPaymentReportFilter, format)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (res: any) => {
            this.toastr.success(this.tS.instant('cc.common.saved-successfully'));
            this.webFile.getFileByIdAndType(res, WebFile.TYPE_CD_EB_BANK_PAYMENT_REPORT_EXPORT, () => {
              this.webFile.downloadFile().subscribe(response => {
                fileSaver.saveAs(response, `${this.webFile.originalFilename}`);
              });
            });
          },
          () => this.toastr.error(this.tS.instant('cc.common.something-went-wrong-please-try-again')),
        );
  }

  onFilterValuesChanged(filterOutput: MkFilterOutput) {
    this.ebPaymentReportFilter.searchTerm = filterOutput.search;
    this.ebPaymentReportFilter.statusCds = filterOutput.selections[McGod.t(EbPaymentReport.FILTER_STATUS)];
    this.ebPaymentReportFilter.types = filterOutput.selections[McGod.t(EbPaymentReport.FILTER_TYPE)];
    this.ebPaymentReportFilter.transactionDatetimeFrom = filterOutput.selections[McGod.t(EbPaymentReport.FILTER_DATE_RANGE)]?.startDate;
    this.ebPaymentReportFilter.transactionDatetimeTo = filterOutput.selections[McGod.t(EbPaymentReport.FILTER_DATE_RANGE)]?.endDate;
    this.ebPaymentReportFilter.matchingTypes = filterOutput.selections[McGod.t(EbPaymentReport.FILTER_MATCHING_TYPE)];
  }
}
